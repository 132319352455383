import React, { useEffect, useState } from "react";
import {useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import Switch from "react-switch";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";
import toastr from "toastr";

const AdminCoupons = () => {
  const [viewModal, setViewModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    amount : "",
    type : "",
    min_order_amount : "",
    expiration_date : "",
    coupon_name : "",
    coupon_description : "",
    max_order_amount : "",
    user_usage_count : "",
    min_order_quantity : "",
    isPublic : false,
    forShipment : false,
    // forMarketplace : false,
    usersendMail : false,
    discription1 : "",
    discription2 : "",
    discription3 : "",
    discription4 : "",
  });
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddCouponModal = () => {
    setViewModal((prev) => !prev);
    setCoupon(null);
  };

  function Offsymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        No
      </div>
    );
  }

  function OnSymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        Yes
      </div>
    );
  }
  useEffect(() => {
    getCoupons();
  }, [user]);

  async function getCoupons() {
    try {
      const content = await post("/api/get-admin-coupons", {
        vendor_id: user?.id,
      });
      setCoupons(content);
    } catch (e) {
      console.log(e);
    }
  }

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: coupons.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];
  console.log("coupons",coupon);
  

  const selectRow = {
    mode: "checkbox",
  };
  async function submitRequest() {
    try {
      console.log("FINAL COUPON",coupon);
      //  if (!coupon?.forShipment && !coupon?.forMarketplace) {
      //    toastr.error("You must select either 'For Shipment' or 'For Marketplace'.");
      //    return;
      //  }
      
      setLoading(true);
      const content = await post("/api/create-admin-coupon", {
        ...coupon,
      });
      setLoading(false);
      getCoupons();
      handleAddCouponModal();
      toastr.success("Request created successfully!");
    } catch (e) {
      toastr.error(e?.response?.data?.error, "Error");
      setLoading(false);
      console.log(e);
    }
  }
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupon Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Coupon Management", link: "/coupons" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={coupons}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={coupons}
                        columns={EcommerceOrderColumns(
                          getCoupons,
                          setCoupon,
                          setViewModal
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddCouponModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Create
                                    New Coupon
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="md" isOpen={viewModal} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {coupon?.id ? "Edit" : "New"} Coupon Form
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponcode">Coupon Code</Label>
                <Input
                  type="text"
                  id="couponcode"
                  placeholder="Enter Coupon Code"
                  value={coupon?.coupon_name}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\s/g, ""); // Remove all spaces
                    setCoupon((prev) => ({
                      ...prev,
                      coupon_name: value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponcode">Coupon Description</Label>
                <Input
                  type="text"
                  id="coupondes"
                  placeholder="Eg: Get 20% off on your next purchase!"
                  value={coupon?.coupon_description}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      coupon_description: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Minimum Order Value</Label>
                <Input
                  type="string"
                  id="minordervalue"
                  placeholder="Enter Minimum Order Value"
                  value={
                    parseInt(
                      coupon?.min_order_amount
                        ?.toString()
                        ?.replace(/[^0-9.]/g, "")
                    ) || 0
                  }
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_amount: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Maximum Order Value</Label>
                <Input
                  type="string"
                  id="maxordervalue"
                  placeholder="Enter Maximum Order Value"
                  value={coupon?.max_order_amount}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      max_order_amount:
                        parseInt(
                          e.target.value.toString()?.replace(/[^0-9.]/g, "")
                        ) || 0,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Minimum Order Quantity</Label>
                <Input
                  type="string"
                  id="minorderquantity"
                  placeholder="Enter Minimum Order Quantity"
                  value={coupon?.min_order_quantity}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_quantity:
                        parseInt(
                          e.target.value.toString()?.replace(/[^0-9]/g, "")
                        ) || 0,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">User Usage Count</Label>
                <Input
                  type="string"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  id="usrusagecount"
                  placeholder="Enter User Usage Count"
                  value={coupon?.user_usage_count}
                  onChange={(e) => {
                    const value = parseInt(
                      e.target.value.toString()?.replace(/[^0-9.]/g, 0)
                    );

                    setCoupon((prev) => ({
                      ...prev,
                      user_usage_count: !isNaN(value) ? value : "",
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="expiry">Expiration Date</Label>
                <DatePicker
                  selected={coupon?.expiration_date}
                  onChange={(date) => {
                    setCoupon((prev) => ({
                      ...prev,
                      expiration_date: date,
                    }));
                  }}
                  minDate={tomorrow}
                  placeholderText="Select expiration date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <Label for="percenttrue">Make Coupon Amount Percentage?</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({ ...prev, type: !coupon?.type }))
                  }
                  checked={coupon?.type}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponamount">
                  Coupon {coupon?.type ? "Percentage" : "Amount"}
                </Label>
                <Input
                  type="number"
                  id="couponamount"
                  max={coupon?.type === true ? 100 : 100000}
                  placeholder={
                    coupon?.type ? "Enter Percentage" : "Enter Amount"
                  }
                  value={coupon?.amount}
                  onChange={(e) => {
                    const value = parseInt(e.target.value) || "";
                    if (coupon?.type && (value < 0 || value > 100)) {
                    } else {
                      setCoupon((prev) => ({
                        ...prev,
                        amount: value,
                      }));
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <Label for="percenttrue">Visible in View Promo code</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      isPublic: !coupon?.isPublic,
                    }))
                  }
                  checked={coupon?.isPublic}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <Label for="percenttrue">Send Email to All Users</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      usersendMail: !coupon?.usersendMail,
                    }))
                  }
                  checked={coupon?.usersendMail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <Label for="percenttrue">For Shipment</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      forShipment: !coupon?.forShipment,
                    }))
                  }
                  // onChange={() =>
                  //   setCoupon((prev) => ({
                  //     ...prev,
                  //     forShipment: !prev.forShipment,
                  //     forMarketplace: prev?.forShipment
                  //       ? prev?.forMarketplace
                  //       : false,
                  //   }))
                  // }
                  checked={coupon?.forShipment}
                />
              </FormGroup>
            </Col>
            {/* <Col md="6">
              <Label for="percenttrue">For Marketplace</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  // onChange={() =>
                  //   setCoupon((prev) => ({
                  //     ...prev,
                  //     forMarketplace: !coupon?.forMarketplace,
                  //   }))
                  // }
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      forMarketplace: !prev?.forMarketplace,
                      forShipment: prev?.forMarketplace
                        ? prev?.forShipment
                        : false,
                    }))
                  }
                  checked={coupon?.forMarketplace}
                />
              </FormGroup>
            </Col> */}
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription1">Coupon Description Point 1</Label>
                <Input
                  type="text"
                  id="discription1"
                  placeholder="please enter discription"
                  value={coupon?.discription1}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription1: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription2">Coupon Description Point 2</Label>
                <Input
                  type="text"
                  id="discription2"
                  placeholder="please enter discription"
                  value={coupon?.discription2}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription2: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription3">Coupon Description Point 3</Label>
                <Input
                  type="text"
                  id="discription3"
                  placeholder="please enter discription"
                  value={coupon?.discription3}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription3: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription4">Coupon Description Point 4</Label>
                <Input
                  type="text"
                  id="discription4"
                  placeholder="please enter discription"
                  value={coupon?.discription4}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription4: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
            }}
          >
            <Button
              size="md"
              disabled={loading}
              onClick={submitRequest}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              {loading ? (
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AdminCoupons;
