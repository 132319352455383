import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import ActionDropdown from "../../components/ActionDropdown";
import OrderActionDropDOwn from "../../components/OrderActionDropdown";
import UserActionDropdown from "../../components/UsersActionDropdown";

const EcommerceOrderColumns = (
  getAllVendorRequests,
  setDocuments,
  handleShowImageModal,
  activeTab,
  setSelectedUserEmail
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "user id",
    text: "No",
    sort: true,
    formatter: (cellContent, row, rowIndex) => (
      <Link to="#" className="text-body fw-bold">
        {row ? rowIndex + 1 : "-"}
      </Link>
    ),
  },
  {
    dataField: "user id",
    text: "User Id",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.user_id ? row.user_id : "-"}
      </Link>
    ),
  },
  {
    dataField: "first_name",
    text: "First Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.first_name || ""}
      </Link>
    ),
  },

  {
    dataField: "last_name",
    text: "Last Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.last_name || ""}
      </Link>
    ),
  },
  {
    dataField: "mobilenumber",
    text: "User Mobile",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.mobilenumber
          ? row?.country_code + "-" + row?.mobilenumber
          : "Gmail"}
      </Link>
    ),
  },
  {
    dataField: "email",
    text: "User Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.email ? row.email : "-"}
      </Link>
    ),
  },

  activeTab === "SUSPEND" && {
    dataField: "remarks",
    isDummyField: true,
    text: "Remarks",
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.remarks || "-"}
      </Link>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <UserActionDropdown
        activeTab={activeTab}
        row={row}
        fetchDataAgain={getAllVendorRequests}
      />
    ),
  },
  {
    dataField: "update_user",
    isDummyField: true,
    text: "Update User",
    formatter: (cellContent, row) => (
      <Button
        color="primary"
        onClick={() => {
          setDocuments(row);
          handleShowImageModal();
        }}
      >
        UPDATE
      </Button>
    ),
  },
  {
    dataField: "send_email",
    isDummyField: true,
    text: "Send Email",
    formatter: (cellContent, row) => (
      <Button
        color="primary"
        onClick={() => {
          setSelectedUserEmail(row.email);
        }}
      >
        Send Mail
      </Button>
    ),
  },
];

export default EcommerceOrderColumns;
