import { ro } from "date-fns/locale";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const EcommerceOrderColumns = (
  getAllVendorRequests,
  categories,
  setDataObject,
  handleModalShow
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => (
      <>
        {/* {row.id}\ */}
        {console.log(row)}
      </>
    ),
  },
  {
    dataField: "name",
    text: "FullName",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.fullName ? row?.fullName : "-"}
      </Link>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.email ? row?.email : "-"}
      </Link>
    ),
  },
  {
    dataField: "number",
    text: "PhoneNumber",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.phoneNumber ? `${row.country_code + " " + row.phoneNumber}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "query",
    text: "Query",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.type_of_query ? row?.type_of_query : "-"}
      </Link>
    ),
  },
  {
    dataField: "module",
    text: "Module",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.module ? row?.module : "-"}
      </Link>
    ),
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.subject ? row?.subject : "-"}
      </Link>
    ),
  },
  {
    dataField: "message",
    text: "Message",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.message ? row?.message : "-"}
      </Link>
    ),
  },
  {
    dataField: "reason",
    text: "Reason",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.reason ? row?.reason : "-"}
      </Link>
    ),
  },
  {
    dataField: "reason",
    text: "View Details",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        <Button
          style={{ backgroundColor: "#00B4D8", color: "white", border: "none" }}
          onClick={() => {
            setDataObject(row); 
            handleModalShow(); 
          }}
        >
          View Details
        </Button>
      </Link>
    ),
  },
];

export default EcommerceOrderColumns;
