import React from "react";
import SearchableDropdown from "./SearchableDropdown";
// import { Button, Row, Col } from "reactstrap";
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { post } from "../helpers/api_helper";
import CustomField from "./CustomTextField";
import toastr from "toastr";
import * as Yup from "yup";
import { useState,useEffect } from "react";
import { formTitleText } from "../store/actions";
import { useDispatch } from "react-redux";
// Validation schema for Formik using Yup
const validationSchema = Yup.object().shape({
  first_name: Yup.string().matches(/^[A-Za-z]+$/, "First Name digits not allow").required("First Name is required"),

  last_name: Yup.string().matches(/^[A-Za-z]+$/, "Last Name digits not allow").required("Last Name is required"),

  email: Yup.string().email("Invalid email format").required("Email is required"),

  password: Yup.string().required("Password is required"),

  country_code: Yup.string().required("Country code is required"),

  mobilenumber: Yup.string().matches(/^[0-9]+$/, "Mobile number can only contain digits").length(10, "Mobile number must be exactly 10 digits").required("Mobile number is required"),
});
function Stage1SelectUserAndPackageSize({
  setCurrentStage,
  setSelectedUser,
  setSelectedPackageSize,
  setShipmentCharges,
  selectedPackageSize,
  selectedUser,
  customPackageSize,
  setCustomPackageSize,
  setInsuranceCharges,
  setCategory,
  setSubCategory,
  subCategory,
  category,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) {
  const [users, setUsers] = React.useState([]);
  const [packageSizes, setPackageSizes] = React.useState([]);
   const dispatch = useDispatch();

   useEffect(() => {
     dispatch(formTitleText("Create Order"));
   }, []);
  async function getAllUsers() {
    try {
      const content = await post("/api/auth/get-all-users", {});
      if (content.length) {
        const users = content.map((el) => ({
          label: `${el?.first_name || " "} ${el?.last_name || " "}(${
            el?.email || " "
          }) (${el?.country_code || " "}${el?.mobilenumber || " "})`,
          value: el.id,
        }));
        setUsers(users || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
  React.useEffect(() => {
    if (selectedCategory?.value) {
      getSubCategoriesFromCategory(selectedCategory?.value);
    }
  }, [selectedCategory?.value]);
  async function getCategoriesAndSubCategories() {
    try {
      const content = await post("/api/fetch-categories", {});
      if (content.categories) {
        const categories = content.categories.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setCategory(categories || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function getSubCategoriesFromCategory(parentId) {
    try {
      const content = await post("/api/fetch-subcategories", {
        category_id: parentId,
      });
      if (content.length) {
        const categories = content.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setSubCategory(categories || []);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllPackageSizes() {
    try {
      const content = await post("/api/get-package-size", {});
      setShipmentCharges(content?.shipmentCharges || []);
      setInsuranceCharges(content?.insuranceCharges || []);
      if (content?.packageSizes?.length) {
        const sizes = content?.packageSizes.map((el) => ({
          ...el,
          label: el.size_name,
          value: el.id,
        }));
        setPackageSizes(sizes || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
  React.useEffect(() => {
    getAllUsers();
    getAllPackageSizes();
    getCategoriesAndSubCategories();
  }, []);
  const handleSelect = (selectedOption) => {
    setSelectedUser(selectedOption);
  };
  const handleSelectPackage = (selectedOption) => {
    setSelectedPackageSize(selectedOption);
  };

   const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);


  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const response = await post("/api/auth/create-user-admin", values);      
      toastr.success("User created successfully");
      resetForm();
      toggleModal(); 
      getAllUsers();
    } catch (error) {
      toastr.error("User Already Registered");
    }
  };

  return (
    <>
      <div
        className="create-user"
        style={{ width: "100%", display: "flex", justifyContent: "end" }}
      >
        <button
          type="button"
          className="create-user-btn"
          style={{ border: "none", background: "transparent" }}
          onClick={toggleModal}
        >
          + Create User
        </button>
      </div>

      {/* Modal Popup Form */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create User</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              country_code: "",
              mobilenumber: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <Field
                        name="first_name"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <Field
                        name="password"
                        type="password"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="country_code" className="form-label">
                        Country Code
                      </label>
                      <Field
                        as="select"
                        name="country_code"
                        className="form-control"
                      >
                        <option value="">Select Country Code</option>
                        <option value="+1">+1 (Canada)</option>
                        <option value="+91">+91 (India)</option>
                      </Field>
                      <ErrorMessage
                        name="country_code"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="mobilenumber" className="form-label">
                        Mobile Number
                      </label>
                      <Field
                        name="mobilenumber"
                        type="tel"
                        className="form-control"
                        maxLength="10"
                      />
                      <ErrorMessage
                        name="mobilenumber"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                </Row>

                <Button type="submit" color="primary" className="primary">
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      <div>
        <Row>
          <Col md="6">
            <h5 style={{ marginTop: "10px" }}>Select Category</h5>
            <div>
              <SearchableDropdown
                defaultValue={selectedCategory}
                options={category}
                onSelect={setSelectedCategory}
              />
            </div>
          </Col>
          <Col md="6">
            <h5 style={{ marginTop: "10px" }}>Select Sub Category</h5>
            <div>
              <SearchableDropdown
                defaultValue={selectedSubCategory}
                options={subCategory}
                onSelect={setSelectedSubCategory}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <h5 style={{ marginTop: "10px" }}>Select User</h5>
            <div>
              <SearchableDropdown
                defaultValue={selectedUser}
                options={users}
                onSelect={handleSelect}
              />
            </div>
          </Col>
          <Col md="6">
            <h5 style={{ marginTop: "10px" }}>Select Package Size</h5>
            <div>
              <SearchableDropdown
                options={[
                  ...packageSizes.map((packageSize) => ({
                    ...packageSize,
                    label: `${packageSize.label} [ ${packageSize.size_weight} KG ]`,
                  })),
                  { label: "Custom", value: "Custom" },
                ]}
                onSelect={handleSelectPackage}
                defaultValue={selectedPackageSize}
              />
            </div>
          </Col>
        </Row>
      </div>
      {selectedPackageSize?.value === "Custom" ? (
        <ShowCustomPackageSizeForm
          setCustomPackageSize={setCustomPackageSize}
          customPackageSize={customPackageSize}
        />
      ) : (
        ""
      )}
      <Button
        style={{ marginTop: "30px" }}
        color="primary"
        className="primary"
        disabled={
          !selectedUser ||
          !selectedPackageSize ||
          !selectedCategory ||
          !selectedSubCategory
        }
        onClick={() => {
          if (selectedPackageSize?.value === "Custom") {
            if (!customPackageSize?.size_length) {
              toastr.error("Please enter custom package size length");
              return;
            }
            if (!customPackageSize?.size_width) {
              toastr.error("Please enter custom package size width");
              return;
            }
            if (!customPackageSize?.size_height) {
              toastr.error("Please enter custom package size height");
              return;
            }
            if (!customPackageSize?.size_weight) {
              toastr.error("Please enter custom package size weight");
              return;
            }
          }
          if (!selectedCategory) {
            toastr.error("Please select category");
            return;
          }
          if (!selectedSubCategory) {
            toastr.error("Please select sub category");
            return;
          }
          setCurrentStage((prev) => prev + 1);
        }}
      >
        Next
      </Button>
    </>
  );
}

function ShowCustomPackageSizeForm({
  customPackageSize,
  setCustomPackageSize,
}) {
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setCustomPackageSize((prev) => ({
      ...prev,
      [name]: name === "size_name" ? value : parseFloat(value),
    }));
  };
  return (
    <>
      <Row style={{ marginTop: "30px" }}>
        {/* <Col>
          <CustomField
            fieldName="size_name"
            fieldType="text"
            label="Size Name"
            value={customPackageSize?.size_name}
            onChange={handleObjectChange}
          />
        </Col> */}
        <Col>
          <CustomField
            fieldName="size_length"
            fieldType="number"
            label="Size Length"
            value={customPackageSize?.size_length}
            onChange={handleObjectChange}
          />
        </Col>
        <Col>
          <CustomField
            fieldName="size_weight"
            fieldType="number"
            label="Size Weight"
            value={customPackageSize?.size_weight}
            onChange={handleObjectChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomField
            fieldName="size_width"
            fieldType="number"
            label="Size Width"
            value={customPackageSize?.size_width}
            onChange={handleObjectChange}
          />
        </Col>
        <Col>
          <CustomField
            fieldName="size_height"
            fieldType="number"
            label="Size Height"
            value={customPackageSize?.size_height}
            onChange={handleObjectChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default Stage1SelectUserAndPackageSize;
