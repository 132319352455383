import React, { useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Spinner,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Switch from "react-switch";
import toastr from "toastr";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../helpers/api_helper";
import { selectCategoryToUpdate } from "../../store/userAuthInfo/actions";
function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
}
const AddCategory = () => {
  const dispatch = useDispatch();
  const selectedCategoryForUpdate = useSelector(
    (state) => state.userAuthInfo.selectedCategoryForUpdate
  );
  const [isSubCategory, setIsSubCategory] = useState(
    !!selectedCategoryForUpdate?.row?.parent_category_id || false
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState({
    name: selectedCategoryForUpdate?.row?.name || "",
    // description: selectedCategoryForUpdate?.row?.description || "",
    // slug: selectedCategoryForUpdate?.row?.slug || "",
  });
  const [selectedItem, setSelectedItem] = useState(
    selectedCategoryForUpdate?.row?.id || null
  );
  const [categories, setCategories] = useState([]);
  const navigation = useHistory();
  const [categoryFile, setCategoryFile] = useState(null);
  const [isActiveCategory, setIsActiveCategory] = useState(
    selectedCategoryForUpdate?.row?.isActive || true
  );
  const [loading, setLoading] = useState(false);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/" },
    { title: "Categories", link: "/categories" },
    { title: "Add New Category", link: "#" },
  ];
  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowDropdown((prev) => !prev);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryInfo((prev) => ({ ...prev, [name]: value }));
  };
  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  React.useEffect(() => {
    getAllCategories();
    return () => {
      dispatch(selectCategoryToUpdate(null));
    };
  }, []);
  async function getAllCategories() {
    try {
      const content = await post("/api/get-categories", {});
      setCategories(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleCategoryFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setCategoryFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  const handleSubmit = async (event, values) => {
    try {
      setLoading(true);
      if (
        categoryInfo.name === ""
        // categoryInfo.description === "" ||
        // categoryInfo.slug === ""
      ) {
        let errorMessage = "Please fill in the following fields:\n";
        if (categoryInfo.name === "") {
          errorMessage += "- Name\n";
        }
        // if (categoryInfo.description === "") {
        //   errorMessage += "- Description\n";
        // }
        // if (!categoryFile) {
        //   errorMessage += "- Category image\n";
        // }
        // if (categoryInfo.slug === "") {
        //   errorMessage += "- Slug\n";
        // }
        if (isSubCategory && !selectedItem) {
          errorMessage += "- Parent category is required\n";
        }
        alert(errorMessage);

        setLoading(false);
      } else {
        const formData = new FormData();
        formData.append("categoryImage", categoryFile);

        formData.append(
          "objectDetails",
          JSON.stringify({
            ...categoryInfo,
            ...(selectedItem && { parentCategoryId: selectedItem.id }),
            ...(selectedCategoryForUpdate?.row?.id && {
              id: selectedCategoryForUpdate?.row?.id,
            }),
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        let responseObj = await fetch(
           `${process.env.REACT_APP_AUTHDOMAIN}/api/create-category`,
          config
        );
        const res = await responseObj.json();
        if (res?.message) {
          toastr.error(res?.message, "Error");
          setLoading(false);
          return;
        }
        navigation.push("/categories");
        setLoading(false);
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Add Category" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    {/* <Nav className="twitter-bs-wizard-nav nav nav-pills nav-justified"></Nav> */}

                    <TabContent
                      activeTab={1}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <div style={{paddingTop : 20}}>
                          <CardTitle className="h5">
                            Basic Information
                          </CardTitle>
                        </div>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="productname">
                            Category Name
                          </Label>
                          <Input
                            id="productname"
                            name="name"
                            type="text"
                            value={categoryInfo?.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                        {/* <div className="mb-3">
                          <Label className="form-label" htmlFor="slug">
                            Slug
                          </Label>
                          <Input
                            id="slug"
                            value={categoryInfo?.slug}
                            onChange={handleChange}
                            name="slug"
                            type="text"
                            className="form-control"
                          />
                        </div> */}

                        {/* <div className="mb-3">
                          <label className="form-label" htmlFor="productdesc">
                            Category Description
                          </label>
                          <textarea
                            className="form-control"
                            id="productdesc"
                            rows="5"
                            value={categoryInfo?.description}
                            onChange={handleChange}
                            name="description"
                          ></textarea>
                        </div> */}
                        <div className="mb-3">
                          <Label
                            className="form-label me-4"
                            htmlFor="categoryPublish"
                          >
                            Publish
                          </Label>
                          <Switch
                            id="categoryPublish"
                            uncheckedIcon={<Offsymbol />}
                            className="me-1 mb-sm-8 mb-2 "
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() =>
                              setIsActiveCategory((prev) => !prev)
                            }
                            checked={isActiveCategory}
                          />
                        </div>
                        {/* <div className="mb-3">
                          <Label for="pan">Category image</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="file"
                              id="category"
                              onChange={handleCategoryFileChange}
                            />
                            <img
                              src={
                                categoryFile?.preview
                                  ? categoryFile?.preview
                                  : selectedCategoryForUpdate?.row?.image_url
                                  ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedCategoryForUpdate?.row?.image_url}`
                                  : ``
                              }
                              width="150px"
                            />
                          </FormGroup>
                        </div> */}
                      </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li className={"next"} onClick={handleSubmit}>
                        {loading ? (
                          <Spinner
                            style={{ width: "2rem", height: "2rem" }}
                            children={false}
                          />
                        ) : (
                          <Link to="#">Save</Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCategory;
