import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import ActionDropdown from "../../components/ActionDropdown";
import ProductActionDropdown from "../../components/ProductActionDropdown";
import CouponActionDropdown from "../../components/CouponActionDropdown";

const EcommerceOrderColumns = (
  getCoupons,setCoupon,setViewModal
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>
    {row.id}</>,
  },
  {
    dataField: "coupon_name",
    text: "Coupon Code",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.coupon_name}
      </Link>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.amount}
      </Link>
    ),
  },

  {
    dataField: "type",
    text: "Type of Coupon",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.type === "PERCENTAGE"
            ? "success"
            : row.type === "ABSOLUTE"
            ? "warning"
            : "danger")
        }
        color={
          row.type === "PERCENTAGE"
            ? "success"
            : row.type === "ABSOLUTE"
            ? "warning"
            : "danger"
        }
        pill
      >
        {row.type}
      </Badge>
    ),
  },
  {
    dataField: "expiration_date",
    text: "Expiration On",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {new Date(row.expiration_date).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}
      </Link>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <CouponActionDropdown
        row={row}
        getCoupons={getCoupons}
        setCoupon={setCoupon}
        setViewModal={setViewModal}
      />
    ),
  },
];

export default EcommerceOrderColumns;
