import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { get, put } from "../helpers/api_helper";
import toastr from "toastr";
import SearchableDropdown from "../components/SearchableDropdown";

function UserActionDropdown({ row, fetchDataAgain, activeTab }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSuspendPopup, setShowSuspendPopup] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [emailTemplate, setEmailTemplate] = useState({
    title: "",
    subject: "",
    message: "",
  });
  console.log("selected template",emailTemplate.id);
  
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  async function getEmailTemplates() {
    try {
      const content = await get("/api/email-template");

      setEmailTemplateList(content?.emailTemplates || []);
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }
  useEffect(() => {
    getEmailTemplates();
  }, []);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSuspendPopup = () => {
    setShowSuspendPopup(!showSuspendPopup);
  };

  const handleItemClick = (item) => {
    if (item === "SUSPEND") {
      setShowSuspendPopup(true);
    } else {
      updateVendorRequestStatus(item);
    }
    setDropdownOpen(false);
  };

  const handleSuspend = async () => {
    try {
      if(!remarks && !emailTemplate.id){
        toastr.error("Please enter remarks")
        return
      }
      await put(`/api/auth/update-users-admin/${row.id}`, {
        status: "SUSPEND",
        remarks: remarks,
        email_template_id : emailTemplate?.id
      });
      toastr.success("User suspended with remarks!", "Success");
      fetchDataAgain();
      setRemarks("");
      setShowSuspendPopup(false);
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  };

  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/auth/update-users-admin/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle color="primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {activeTab === "ACTIVE" ? (
            <DropdownItem onClick={() => handleItemClick("SUSPEND")}>
              <i className="mdi mdi-clock font-size-18 me-2"></i>SUSPEND
            </DropdownItem>
          ) : (
            <DropdownItem onClick={() => handleItemClick("ACTIVE")}>
              <i className="mdi mdi-check font-size-18 me-2"></i>ACTIVE
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      {/* Suspend Remarks Popup */}
      <Modal isOpen={showSuspendPopup} toggle={toggleSuspendPopup}>
        <ModalHeader toggle={toggleSuspendPopup}>
          Add Remarks for Suspension
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter remarks here..."
          />
        </ModalBody>
        <ModalBody>
          <div className="mb-3">
            <h6 style={{ marginTop: "10px" }}>Select Template</h6>
            <SearchableDropdown
              defaultValue={emailTemplate}
              options={emailTemplateList.map((el) => ({
                ...el,
                label: el.title,
                value: el.id,
              }))}
              placeholder="Select email template"
              onSelect={setEmailTemplate}
            />
          </div>
          {/* <Form>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Title"
                value={emailTemplate?.title}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </div>

            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Subject"
                value={emailTemplate?.subject}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="mb-3">
              <Editor
                containerProps={{ style: { height: "200px" } }}
                value={emailTemplate?.message}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }));
                }}
              />
            </div>
          </Form> */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSuspend}>
            Submit
          </Button>
          <Button color="secondary" onClick={toggleSuspendPopup}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UserActionDropdown;
