// import React, { Component } from "react";
// import { Col, Card, CardBody } from "reactstrap";
// import { withRouter } from "react-router-dom";
// import { formatDollars } from "../../helpers/amountFormatter";

// class MiniWidgets extends Component {
//   handleCardClick = (report) => {
//     this.props.history.push(report);
//   };

//   render() {
//     return (
//       <React.Fragment>
//         {this.props.reports.map((report, key) => {
//           const value =
//             report.title === "Total Revenue"
//               ? formatDollars(report.value)
//               : report.title === "Total Order Weight"
//               ? `${report.value} KG`
//               : report.value;

//           return (
//             <Col key={key} md={4}>
//               <Card
//                 onClick={() => this.handleCardClick(report.route)}
//                 style={{ cursor: "pointer" }}
//               >
//                 <CardBody>
//                   <div className="d-flex">
//                     <div className="flex-1 overflow-hidden">
//                       <p className="text-truncate font-size-14 mb-2">
//                         {report.title}
//                       </p>
//                       <h4 className="mb-0">{value}</h4>
//                     </div>
//                     <div className="text-primary">
//                       <i className={report.icon + " font-size-24"}></i>
//                     </div>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           );
//         })}
//       </React.Fragment>
//     );
//   }
// }

// export default withRouter(MiniWidgets);



import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { formatDollars } from "../../helpers/amountFormatter";

class MiniWidgets extends Component {
  handleCardClick = (report) => {
    this.props.history.push(report);
  };

  groupReportsIntoRows = (reports) => {
    const rows = [];
    let currentIndex = 0;

    // First row: 3 widgets
    if (reports.length > 0) {
      rows.push(reports.slice(currentIndex, currentIndex + 3));
      currentIndex += 3;
    }

    // Subsequent rows: 4 widgets each
    while (currentIndex < reports.length) {
      rows.push(reports.slice(currentIndex, currentIndex + 4));
      currentIndex += 4;
    }

    return rows;
  };

  render() {
    const rows = this.groupReportsIntoRows(this.props.reports);

    return (
      <React.Fragment>
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map((report, key) => {
              const value =
                report.title === "Total Revenue"
                  ? formatDollars(report.value)
                  : report.title === "Total Order Weight"
                  ? `${report.value} KG`
                  : report.value;

              return (
                <Col
                  key={key}
                  md={row.length === 3 ? 4 : 3} // Adjust column size based on row count
                >
                  <Card
                    onClick={() => this.handleCardClick(report.route)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {report.title}
                          </p>
                          <h4 className="mb-0">{value}</h4>
                        </div>
                        <div className="text-primary">
                          <i className={report.icon + " font-size-24"}></i>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ))}
      </React.Fragment>
    );
  }
}

export default withRouter(MiniWidgets);
