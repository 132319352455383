import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { put } from "../helpers/api_helper";
import toastr from "toastr";
import { useSelector } from "react-redux";
function ActionDropdown({ row, getAllVendorRequests }) {
   const activeTab = useSelector((state) => state.userAuthInfo.activeTab);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
     const [reason, setReason] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
   const togglePopup = () => {
     setShowPopup(!showPopup);
      setReason("");
   };

  const handleItemClick = (item) => {
    // setSelectedItem(item);
    // setDropdownOpen(false);
    // updateVendorRequestStatus(item);
     if (item === "BLOCKED") {
       setShowPopup(true);
     } else {
       updateVendorRequestStatus(item);
     }
     setDropdownOpen(false);
  };
   const confirmBlockAction = async () => {
     if (!reason.trim()) {
       toastr.error("Please provide a reason for blocking.", "Error");
       return;
     }
     setShowPopup(false);
     updateVendorRequestStatus("BLOCKED");
   };
  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/vendor-request/${row.id}`, {
        status: status,
        reason : reason
      });
      setReason("")
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle caret color="primary" className="primary">
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {activeTab !== "ACTIVE" && (
            <DropdownItem onClick={() => handleItemClick("ACTIVE")}>
              <i className="mdi mdi-check font-size-18 me-2"></i>ACTIVE
            </DropdownItem>
          )}
          {activeTab !== "BLOCKED" && (
            <DropdownItem onClick={() => handleItemClick("BLOCKED")}>
              <i className="mdi mdi-block-helper font-size-18 me-2"></i>BLOCK
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      {/* Popup for confirmation */}
      <Modal isOpen={showPopup} toggle={togglePopup}>
        <ModalHeader toggle={togglePopup}>Confirm Action</ModalHeader>
        <ModalBody>
          <p>
          Enter Reason 
          </p>
          <Input
            type="textarea"
            placeholder="Enter the reason for blocking"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={4}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmBlockAction}>
            Yes, Block
          </Button>
          <Button color="secondary" onClick={togglePopup}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ActionDropdown;
