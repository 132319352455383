import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import toastr from "toastr";

import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";
import PackageEnquiryActionDropdown from "../../components/PackageEnquiry";

const PackageEnquiry = () => {
  const navigation = useHistory();
  const [activeTab, setActiveTab] = useState("ALL");
  const [followUpMessages, setFollowUpMessages] = useState([]);
  const [dataObject, setDataObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [followUpText, setFollowUpText] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userAuthInfo.user);

  const handleModalShow = () => {
    setModalShow((prev) => !prev);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  async function handleNewDataObjectCreation() {
    if (!dataObject?.shipping_charges_type) {
      toastr.error("shipping type is required");
      return;
    }
    if (!dataObject?.Order_charges_type) {
      toastr.error("order type is required");
      return;
    }
    try {
      setLoading(true);
      await post("/api/shipping-charges-create", dataObject);

      handleModalShow();
      getAllCategories();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message || "something went wrong");
    }
  }
  async function fetchFollowUpMessage(id) {
    try {
      const followUps = await post("/api/follow-up/fetch", {
        shipment_enquiry_id: id,
      });
      setFollowUpMessages(followUps);
    } catch (e) {
      toastr.error(e?.response?.data?.message || "something went wrong");
    }
  }
  async function addFollowUpMessage() {
    try {
      if (!followUpText) {
        toastr.error("Follow-up Note is required");
        return;
      }
      await post("/api/follow-up/add", {
        shipment_enquiry_id: dataObject.id,
        vendor_id: user.id,
        description: followUpText,
      });
      setFollowUpText("");
      fetchFollowUpMessage(dataObject.id);
    } catch (e) {
      toastr.error(e?.response?.data?.message || "something went wrong");
    }
  }
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setDataObject((prev) => ({
      ...prev,
      [name]: value ? parseInt(value) : 0,
    }));
  };
  function getTotalFieldValue() {
    const local_delivery_charge = dataObject?.local_delivery_charge
      ? dataObject?.local_delivery_charge
      : 0;
    const international_courier_charge =
      dataObject?.international_courier_charge
        ? dataObject?.international_courier_charge
        : 0;
    const human_resource_charge = dataObject?.human_resource_charge
      ? dataObject?.human_resource_charge
      : 0;
    const taxes = dataObject?.taxes ? dataObject?.taxes : 0;
    let grandTotal = local_delivery_charge + international_courier_charge;
    if (dataObject?.Order_charges_type === "ECOM") {
      grandTotal += human_resource_charge;
    }
    const taxAmount = (grandTotal * taxes) / 100;
    return taxAmount + grandTotal;
  }
  useEffect(() => {
    getAllCategories();
  }, [activeTab]);
  async function getAllCategories() {
    try {
      const content = await post("/api/get-package-enquiry", {
        status: activeTab,
      });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  console.log("active tab",activeTab);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Shipment Enquiry"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Shipment Enquiry", link: "/shipment-enquiries" },
            ]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={`${activeTab === "ALL" ? "active" : ""}`}
                        onClick={() => toggleTab("ALL")}
                      >
                        ALL
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${activeTab === "PENDING" ? "active" : ""}`}
                        onClick={() => toggleTab("PENDING")}
                      >
                        PENDING
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${
                          activeTab === "WORK_IN_PROGRESS" ? "active" : ""
                        }`}
                        onClick={() => toggleTab("WORK_IN_PROGRESS")}
                      >
                        WORK IN PROGRESS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${
                          activeTab === "RESOLVED" ? "active" : ""
                        }`}
                        onClick={() => toggleTab("RESOLVED")}
                      >
                        RESOLVED
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={`${activeTab === "CLOSED" ? "active" : ""}`}
                        onClick={() => toggleTab("CLOSED")}
                      >
                        CLOSED
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          setDataObject,
                          handleModalShow,
                          getAllCategories,
                          fetchFollowUpMessage,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                {/* <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleModalShow();
                                      setDataObject({});
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    Shipment Charge
                                  </a>
                                </div> */}
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={modalShow} toggle={handleModalShow}>
        <ModalHeader toggle={handleModalShow}>Enquiry Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <h5>Enquiry Message</h5>
              <p> {dataObject?.message}</p>
            </Col>
            <Col>
              <h5 style={{ marginLeft: "10px" }}>User Detail</h5>
              <table
                style={{
                  borderCollapse: "separate",
                  borderSpacing:
                    "10px"
                }}
              >
                <tr>
                  <th style={{ fontWeight: "bold" }}> Name:</th>
                  <td>{`${dataObject?.user?.first_name} ${dataObject?.user?.last_name}`}</td>
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}> Email:</th>
                  <td>{dataObject?.user?.email}</td>
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}> Mobile Number:</th>
                  <td>{dataObject?.user?.mobilenumber ? `${dataObject?.user?.country_code} ${dataObject?.user?.mobilenumber}` : "-"}</td>
                </tr>
              </table>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h5>Follow-up message</h5>
                <textarea
                  style={{ width: "100%" }}
                  value={followUpText}
                  onChange={(e) => setFollowUpText(e.target.value)}
                ></textarea>
                <Button
                  onClick={addFollowUpMessage}
                  color="primary"
                  className="primary"
                >
                  Add Follow-up Message
                </Button>
              </div>
            </Col>
            <Col>
              <h5 style={{ marginBottom: "20px" }}>Change Enquiry Status</h5>
              <PackageEnquiryActionDropdown
                row={dataObject}
                activeTab={activeTab}
                getAllCategories={getAllCategories}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ marginTop: "20px" }}>
              <h5>Follow-ups</h5>
              <Table bordered hover responsive striped>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Follow-up Note</th>
                    <th>Added By</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {followUpMessages.map((item) => {
                    return (
                      <tr>
                        <td>{item?.description}</td>
                        <td>{`${item?.vendor?.username}`}</td>
                        <td>{new Date(item?.createdAt)?.toLocaleString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PackageEnquiry;
