import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageSizesActionDropdown from "../../components/PackageSizesActionDropdown";

const EcommerceOrderColumns = (
  setDataObject,
  handleModalShow,
  getAllCategories
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "size_name",
    text: "Size Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.size_name}
      </Link>
    ),
  },
  {
    dataField: "size_length",
    text: "Size Length",
    sort: true,
    formatter: (cellContent, row) => (
      <span className="text-body">
        {row.size_length} {row.inch ? "In" : "Ft"}
      </span>
    ),
  },
  {
    dataField: "size_width",
    text: "Size width",
    sort: true,
    formatter: (cellContent, row) => (
      <span className="text-body">
        {row.size_width} {row.inch ? "In" : "Ft"}
      </span>
    ),
  },
  {
    dataField: "size_height",
    text: "Size Height",
    sort: true,
    formatter: (cellContent, row) => (
      <span className="text-body">
        {row.size_height} {row.inch ? "In" : "Ft"}
      </span>
    ),
  },
  {
    dataField: "size_weight",
    text: "Size Weight",
    sort: true,
    formatter: (cellContent, row) => (
      <span className="text-body">{row.size_weight} KG</span>
    ),
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <PackageSizesActionDropdown
        row={row}
        setDataObject={setDataObject}
        handleModalShow={handleModalShow}
        getAllCategories={getAllCategories}
      />
    ),
  },
];

export default EcommerceOrderColumns;
