import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toastr from "toastr";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  List,
  ModalFooter,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import ImageAndUrlHandler from "../../components/AddNewSliderImage";
import AddProductToPopularList from "../../components/AddProductToPopularList";

const ContentManagementWebsite = () => {
  const user = useSelector((state) => state.userAuthInfo.user);
  const navigation = useHistory();
  const [viewModal, setViewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [sliderImages, setSliderImages] = React.useState([]);
  const [secondSliderImages, setSecondSliderImage] = React.useState([])
  const [imageAds, setImageAds] = React.useState([]);
  const [imageFile, setImageFile] = React.useState(null);
  const [adImageFile, setAdImageFile] = React.useState(null);
  const [urlForSliderImage, setUrlForSliderImage] = React.useState("");
  const [urlForImageAd, setUrlForImageAd] = React.useState("");
  const [sections,setSections] = useState([])
  const [category_page_images, setCategory_page_images] = useState([])

  React.useEffect(() => {
    fetchAllWebsiteSliderImages();
    fetchAllWebsiteImageAds();
    fetchAllWebsiteSliderImagesSecond();
    fetchSections();
    fetchCategoryPageImage();
  }, []);

  async function fetchAllWebsiteSliderImages() {
    try {
      const response = await get("/api/all-banner-image-pages");
      if (response?.allBannerImages) {
        setSliderImages(response?.allBannerImages);
      }
    } catch (e) {
      console.log(e);
    }
  }
    async function fetchAllWebsiteSliderImagesSecond() {
      try {
        const response = await get("/api/all-banner-image-pages-second");
        if (response?.allBannerImages) {
          setSecondSliderImage(response?.allBannerImages);
        }
      } catch (e) {
        console.log(e);
      }
    }
  async function fetchAllWebsiteImageAds() {
    try {
      const response = await get("/api/all-offer-image-pages");
      if (response?.allBannerImages) {
        setImageAds(response.allBannerImages);
      }
     
      
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchSections() {
     const res = await get("/api/marketplace-sections");
     setSections(res?.data);
  }
  async function fetchCategoryPageImage () {
    const res = await post("/api/get-category-image");
    setCategory_page_images(res?.data);
  }
  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  const handleSliderImageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setImageFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  const handleAdImageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setAdImageFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };

  const handleSubmitSliderImage = async (event, values) => {
    try {
      if (!urlForSliderImage) {
        toastr.error("Please enter url for slider image");
        return;
      } else if (!imageFile) {
        toastr.error("Please select slider image");
        return;
      } else {
        const formData = new FormData();
        formData.append("sliderImage", imageFile);

        formData.append(
          "objectDetails",
          JSON.stringify({
            attached_url: urlForSliderImage,
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/create-website-slider-image`,
          config
        );
        setImageFile(null);
        setUrlForSliderImage("");
        fetchAllWebsiteSliderImages();
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  };
  const handleSubmitImageAd = async (event, values) => {
    try {
      if (!urlForImageAd) {
        toastr.error("Please enter url for slider image");
        return;
      } else if (!adImageFile) {
        toastr.error("Please select slider image");
        return;
      } else {
        const formData = new FormData();
        formData.append("sliderImage", adImageFile);

        formData.append(
          "objectDetails",
          JSON.stringify({
            attached_url: urlForImageAd,
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/create-website-slider-ads`,
          config
        );
        setAdImageFile(null);
        setUrlForImageAd("");
        fetchAllWebsiteImageAds();
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  };

  async function deleteSliderImage(id) {
    await post(`/api/banner-image-page-delete`, { id });
    fetchAllWebsiteSliderImages();
  }
  async function deleteSliderImageSecond(id) {
    await post(`/api/banner-image-page-delete-second`, { id });
    fetchAllWebsiteSliderImagesSecond();
  }
  async function deleteImageAds(id) {
    await post(`/api/offer-image-page-delete`, { id });
    fetchAllWebsiteImageAds();
  }
    async function deletePopularproduct(item) {
      console.log("items id here it is ",item.id);
      
      await post(`/api/delete-category-image`, { id: item?.id });
      fetchCategoryPageImage();
    }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Content Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Content Management", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h3>Banner Images</h3>
                  {sliderImages?.length ? (
                    <>
                      {sliderImages?.map((item) => {
                        return (
                          <Row style={{ marginTop: "5px" }} key={item?.id}>
                            <Col md={3}>
                              <img
                                height={50}
                                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                                alt=""
                              />
                            </Col>
                            <Col
                              md={3}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <b>{item?.pageTitle}</b>
                            </Col>
                            <Col md={3}>
                              <Button
                                color="primary"
                                className="primary"
                                onClick={() => {
                                  deleteSliderImage(item?.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-18"></i>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <div>No Banner Images Added Yet!</div>
                  )}
                  <Button
                    style={{ marginTop: "30px" }}
                    color="primary"
                    className="primary"
                    onClick={() => {
                      navigation.push("/add-new-page-web");
                    }}
                  >
                    Add New Banner
                  </Button>

                  <h3>Second Banner Images</h3>
                  {secondSliderImages?.length ? (
                    <>
                      {secondSliderImages?.map((item) => {
                        return (
                          <Row style={{ marginTop: "5px" }} key={item?.id}>
                            <Col md={3}>
                              <img
                                height={50}
                                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                                alt=""
                              />
                            </Col>
                            <Col
                              md={3}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <b>{item?.pageTitle}</b>
                            </Col>
                            <Col md={3}>
                              <Button
                                color="primary"
                                className="primary"
                                onClick={() => {
                                  deleteSliderImageSecond(item?.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-18"></i>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <div>No Banner Images Added Yet!</div>
                  )}
                  <Button
                    style={{ marginTop: "30px" }}
                    color="primary"
                    className="primary"
                    onClick={() => {
                      navigation.push("/add-new-offer-page-web-second");
                    }}
                  >
                    Second New Banner
                  </Button>

                  <h3 style={{ marginTop: "30px" }}>Offer Images</h3>
                  {imageAds?.length ? (
                    <>
                      {imageAds?.map((item) => {
                        return (
                          <Row style={{ marginTop: "5px" }} key={item?.id}>
                            <Col md={3}>
                              <img
                                height={50}
                                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                              />
                            </Col>
                            <Col
                              md={3}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <b>{item?.pageTitle}</b>
                            </Col>
                            <Col md={3}>
                              <Button
                                color="primary"
                                className="primary"
                                onClick={() => {
                                  deleteImageAds(item?.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-18"></i>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <div>No Offer Images Added Yet!</div>
                  )}
                  <Button
                    style={{ marginTop: "30px" }}
                    color="primary"
                    className="primary"
                    onClick={() => {
                      navigation.push("/add-new-offer-page-web");
                    }}
                  >
                    Add New Offer
                  </Button>
                  <AddProductToPopularList />

                  <h3 style={{ marginTop: "30px" }}>Sections</h3>
                  {sections?.map((item) => {
                    return (
                      <Row
                        md={6}
                        style={{
                          marginTop: "10px",
                          border: "1px solid #E3E3E3",
                          color: "#5E6363",
                          padding: "5px",
                          marginLeft: "1px",
                          borderRadius: "4px",
                        }}
                      >
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <b>{item?.section_name}</b>
                        </Col>
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="primary"
                            className="primary"
                            onClick={() => {
                              deletePopularproduct(item);
                            }}
                          >
                            <i className="mdi mdi-delete font-size-14"></i>
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Button
                    style={{ marginTop: "30px" }}
                    color="primary"
                    className="primary"
                    onClick={() => {
                      navigation.push("/add-new-products-title");
                    }}
                  >
                    Add New Section
                  </Button>

                  <h3 style={{ marginTop: "30px" }}>Category page images</h3>
                  {category_page_images?.map((item) => {
                    return (
                      <Row
                        md={6}
                        style={{
                          marginTop: "10px",
                          border: "1px solid #E3E3E3",
                          color: "#5E6363",
                          padding: "5px",
                          marginLeft: "1px",
                          borderRadius: "4px",
                        }}
                      >
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={40}
                            src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                            alt=""
                          />
                        </Col>
                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="primary"
                            className="primary"
                            onClick={() => {
                              deletePopularproduct(item);
                            }}
                          >
                            <i className="mdi mdi-delete font-size-14"></i>
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Button
                    style={{ marginTop: "30px" }}
                    color="primary"
                    className="primary"
                    onClick={() => {
                      navigation.push("/add-new-image-category");
                    }}
                  >
                    Add New Image
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={false} toggle={() => {}}>
        <ModalHeader toggle={() => {}}>Add New Vendor</ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContentManagementWebsite;
