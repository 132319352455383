import React from "react";
import { Link } from "react-router-dom";
import { Badge} from "reactstrap";
const EcommerceOrderColumns = (
  getAllVendorRequests,
  setDocuments,
  handleShowImageModal,
  activeTab
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.createAt.split("T")[0] ? `${row.createAt.split("T")[0]}` : "-"}{" "}
      </Link>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.amount ? `$${row?.amount}` : "-"}{" "}
      </Link>
    ),
  },
  activeTab !== "PENDING" && {
    dataField: "note",
    text: "Notes",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {" "}
        {row.note ? row.note : "-"}{" "}
      </Link>
    ),
  },
  activeTab !== "PENDING" && {
    dataField: "image",
    text: "Image",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.image_url ? (
          <img
            src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row.image_url}`}
            alt="admin attachment"
            width="40px"
          />
        ) : (
          "-"
        )}
      </Link>
    ),
  },
  {
    dataField: "status",
    text: "Request Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.status === "ACCEPTED"
            ? "success"
            : row.status === "PENDING"
            ? "warning"
            : "danger")
        }
        color={
          row.status === "ACCEPTED"
            ? "success"
            : row.status === "PENDING"
            ? "warning"
            : "danger"
        }
        pill
      >
        {row.status}
      </Badge>
    ),
  },
];
export default EcommerceOrderColumns;