import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageSizesActionDropdown from "../../components/PackageSizesActionDropdown";

const EcommerceOrderColumns = (
  setDataObject,
  handleModalShow,
  getAllCategories
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "international_courier_charge",
    text: "International Charge",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.international_courier_charge}
      </Link>
    ),
  },
  {
    dataField: "local_delivery_charge",
    text: "Local Charge",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.local_delivery_charge}
      </Link>
    ),
  },
  {
    dataField: "human_resource_charge",
    text: "Human Resource Charge",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.human_resource_charge
          ? row.human_resource_charge
          : "Not Applicable"}
      </Link>
    ),
  },
  {
    dataField: "total",
    text: "Total",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.international_courier_charge +
          row.local_delivery_charge +
          row.human_resource_charge}
        {/* {row.human_resource_charge
          ? row.human_resource_charge
          : "Not Applicable"} */}
      </Link>
    ),
  },
  {
    dataField: "shipping_charges_type",
    text: "Shipment Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.shipping_charges_type === "AIR" ? "success" : "warning")
        }
        color={row.shipping_charges_type === "AIR" ? "success" : "warning"}
        pill
      >
        {row.shipping_charges_type}
      </Badge>
    ),
  },
  // {
  //   dataField: "Order_charges_type",
  //   text: "Order Type",
  //   sort: true,
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "font-size-12 badge-soft-" +
  //         (row.Order_charges_type === "ECOM" ? "success" : "warning")
  //       }
  //       color={row.Order_charges_type === "ECOM" ? "success" : "warning"}
  //       pill
  //     >
  //       {row.Order_charges_type}
  //     </Badge>
  //   ),
  // },
  {
    dataField: "Order_charges_type",
    text: "Order Type",
    sort: true,
    formatter: (cellContent, row) => {
      let badgeColor = "warning"; 
      if (row.Order_charges_type === "SHIPMENT") {
        badgeColor = "success";
      } else if (row.Order_charges_type === "MARKETPLACE") {
        badgeColor = "pink";
      }

      return (
        <Badge
          className={`font-size-12 badge-soft-${badgeColor}`}
          color={badgeColor}
          pill
        >
          {row.Order_charges_type}
        </Badge>
      );
    },
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <PackageSizesActionDropdown
        row={row}
        setDataObject={setDataObject}
        handleModalShow={handleModalShow}
        getAllCategories={getAllCategories}
      />
    ),
  },
];

export default EcommerceOrderColumns;
