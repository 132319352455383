import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const WithdrawalVendor = () => {
  const [viewModal, setViewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("PENDING");
  const [vendorWallet, setVendorWallet] = useState("");
  const [documents, setDocuments] = useState([]);
  const [bankDetails, setBankDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
  };
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    getBankDetails();
    getWithdrawalRequests();
  }, [activeTab, user]);
  async function getBankDetails() {
    try {
      const content = await post("/api/bank-details", { vendor_id: user?.id });
      setBankDetails(content);
    } catch (e) {
      console.log(e);
    }
  }
  async function getWithdrawalRequests() {
    try {
      const content = await post("/api/vendor/withdrawal", {
        vendor_id: user?.id,
        status: activeTab,
      });
      setOrders(content.requests);
      setVendorWallet(content.vendorWallet);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };
  async function submitRequest() {
    try {
      setLoading(true);
      const content = await post("/api/withdrawal", {
        ...bankDetails,
        bankId: bankDetails.id,
        vendor_id: user?.id,
      });
      setLoading(false);
      handleAddVendorModal();
      getBankDetails();
      getWithdrawalRequests();
      toastr.success("Request created successfully!");
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message || "something went wrong");

    }
  }
  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Withdrawal Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Withdrawal Management", link: "/vendor-withdarawal" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <NavLink className={`active`}>
                          Available Balance: {vendorWallet?.balance || "0"}
                        </NavLink>
                      </div>
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PENDING" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PENDING")}
                              >
                                IN PROGRESS
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "ACCEPTED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("ACCEPTED")}
                              >
                                ACCEPTED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "REJECTED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("REJECTED")}
                              >
                                REJECTED
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          () => {},
                          setDocuments,
                          handleShowImageModal,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddVendorModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Make New
                                    Request
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>
          Withdrawal request form
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <NavLink className={`active`}>
              {" "}
              Available Balance: {vendorWallet?.balance || "0"}
            </NavLink>
          </div>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="amount">Amount to be withdrawn</Label>
                <Input
                  type="number"
                  name="bankname"
                  id="amount"
                  placeholder="Enter Amount"
                  value={bankDetails?.amount}
                  onChange={(e) =>
                    setBankDetails((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="bankname">Bank Name</Label>
                <Input
                  type="text"
                  name="bankname"
                  id="bankname"
                  placeholder="Enter bank name"
                  value={bankDetails?.bank_name}
                  onChange={(e) =>
                    setBankDetails((prev) => ({
                      ...prev,
                      bank_name: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="accountholdername">Account Holder Name</Label>
                <Input
                  type="text"
                  name="accountholdername"
                  id="accountholdername"
                  placeholder="Enter account holder name"
                  value={bankDetails?.account_holder_name}
                  onChange={(e) =>
                    setBankDetails((prev) => ({
                      ...prev,
                      account_holder_name: e.target.value,
                    }))
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[0-9]/g, "");
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="accno">Account Number</Label>
                <Input
                  type="text"
                  name="accno"
                  id="accno"
                  placeholder="Enter Account Number"
                  value={bankDetails?.account_number}
                  onChange={(e) =>
                    setBankDetails((prev) => ({
                      ...prev,
                      account_number: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="ifsc">IFSC code</Label>
                <Input
                  type="text"
                  name="accountholdername"
                  id="ifsc"
                  placeholder="Enter IFSC"
                  value={bankDetails?.ifsc_code}
                  onChange={(e) =>
                    setBankDetails((prev) => ({
                      ...prev,
                      ifsc_code: e.target.value,
                    }))
                  }
                  onInput={(e) => {
                    const ifscPattern = /^[A-Za-z]{0,4}0?[A-Za-z0-9]{0,6}$/;
                    if (!ifscPattern.test(e.target.value)) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
            }}
          >
            <Button
              size="md"
              disabled={loading}
              onClick={submitRequest}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              {loading ? (
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>
          Vendor Documents
        </ModalHeader>
        <ModalBody>
          <div>
            {documents?.vendor?.panFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.panFilePath?.replace(/\\/g, "/")}`}
                  width="200px"
                />
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.panFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            {documents?.vendor?.aadharFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.aadharFilePath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  width="200px"
                />
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.aadharFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawalVendor;
