import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import toastr from "toastr";
import Flatpickr from "react-flatpickr";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
} from "reactstrap";
import Editor from "react-simple-wysiwyg";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post, put } from "../../helpers/api_helper";
import SearchableDropdown from "../../components/SearchableDropdown";
import Stage1SelectUserAndPackageSize from "../../components/Stage1SelectUserAndPackageSize";
import Stage2SelectUserAddress from "../../components/Stage2SelectUserAddress";
import { setActiveTab } from "../../store/userAuthInfo/actions";
import MultipleOrderUpdate from "../../components/MultipleOrderUpdate";
import { useMemo } from "react";
const ShipmentOrders = () => {
  const formPageTitle = useSelector((state) => state.Layout.formPageTitle);

  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedOrderForEmail, setSelectedOrderForEmail] = useState(null);
  const [sendEmail, setSendEmail] = useState("");
  const [dateFilter, setDateFilter] = useState([]);
  const [showDifferenceModal, setShowDifferenceModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  var [orders, setOrders] = useState([]);
  const [differenceAmount, setDifferenceAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [reason, setReason] = useState("");
  const [emailTemplate, setEmailTemplate] = useState({
    title: "",
    subject: "",
    message: "",
  });
  const [shipmentType, setShipmentType] = useState("SHIPMENT");
  const [typePass, setTypePass] = useState("SHIPMENT");

  const handleShipmentTypeChange = (type) => {
    setShipmentType(type);
    setTypePass(type);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [activeTab, setActiveTabs] = useState("PLACED");
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const dispatch = useDispatch();
  const handleTabChange = (newTab) => {
    setSelectedRows([]);
    dispatch(setActiveTab(newTab));
    if (activeTab !== newTab) {
      setActiveTabs(newTab);
    }
  };
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
    setDifferenceAmount(0);
  };
  const handleShowDifferenceModal = () => {
    setShowDifferenceModal((prev) => !prev);
  };

  const differenceData = (data) => {
    // setShowDifferenceModal((prev) => !prev);
  };
  function toggleModal() {
    setDisplayModal((prev) => !prev);
    setEmailTemplate({
      title: "",
      subject: "",
      message: "",
    });
    setSelectedOrderForEmail(null);
  }
  function getEmailFromAction(email) {
    setSendEmail(email?.user?.email);
  }
  function getOrderDetails(data) {}
  const [handleData, setHandleData] = useState();
  const [handleOrderDetails, setHandleOrderDetails] = useState();

  function getHandleDifferenceData(handleData, other = undefined) {
    if (other === "orderDetail") {
      setShowImageModal((prev) => !prev);
    }
    setHandleData(handleData.id);
    setHandleOrderDetails(handleData);
    return handleData;
  }
  // function handleShowImageModal(){
  //   setShowImageModal((prev) => !prev);
  // }
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };
  async function createDifferencePaymentLink() {
    try {
      if (!differenceAmount) {
        toastr.error("Please provide difference amount");
        return;
      }
      if (!remarks) {
        toastr.error("Please provide Remarks");
        return;
      }
      setLoading(true);
      console.log("");

      await post("/api/create-custom-difference-payment-link", {
        difference_remarks: remarks,
        difference_amount: parseFloat(differenceAmount),
        // shipment_order_id: documents.id,
        shipment_order_id: handleData,
      });
      await put(`/api/update-shipment-order/${handleData}`, {
        status: "HOLD",
      });
      toastr.success("Success sent payment link");
      handleShowDifferenceModal();
      setRemarks("");
      setDifferenceAmount("");
      setLoading(false);
      getAllVendorRequests();
    } catch (e) {
      setLoading(false);
      console.log(e);
      toastr.error("Error creating payment link");
    }
  }
  async function addReason() {
    try {
      if (!reason) {
        toastr.error("Please provide Reason");
        return;
      }
      setLoading(true);
      await post("/api/add-cancel-reason", {
        reason,
        // id: documents.id,
        id: handleData,
      });
      toastr.success("Success");
      setShowReasonModal(false);
      setReason("");
      setLoading(false);
      getAllVendorRequests();
    } catch (e) {
      setLoading(false);
      console.log(e);
      toastr.error("Error");
    }
  }

  // async function resendPaymentLink(orderId) {
  //   try {
  //     await post("/api/create-custom-payment-link", {
  //       shipment_order_id: orderId,
  //     });
  //     toastr.success("Payment link sent!");
  //   } catch (e) {
  //     console.log(e);
  //     toastr.error("Failed sending payment link");
  //   }
  // }
  useEffect(() => {
    getAllVendorRequests();
    getEmailTemplates();
    // getAllMarketplaceOrders();
  }, [activeTab, user, dateFilter, shipmentType]);

  async function getAllVendorRequests(status) {
    try {
      const content = await post("/api/get-shipment-orders", {
        status: status ? status : activeTab,
        selectedDate: dateFilter,
      });
          const contentMarketplace = await post("/api/ecom/get-all-orders/admin", {
            status: status ? status : activeTab,
            selectedDate: dateFilter,
          });
          let allOrderes = [... contentMarketplace, ...content]
          // setOrders(content);
      setOrders(allOrderes);
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllMarketplaceOrders(status) {
    try {
      const content = await post("/api/ecom/get-all-orders/admin", {
        status: status ? status : activeTab,
        selectedDate: dateFilter,
      });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  // async function handleOrderExport() {
  //   try {
  //     const content = await post("/api/get-shipment-orders-export", {
  //       selectedDate: dateFilter,
  //     });
  //     const ws = XLSX.utils.json_to_sheet(content);
  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //     const excelBinaryString = XLSX.write(wb, {
  //       bookType: "xlsx",
  //       type: "binary",
  //     });
  //     const excelBlob = new Blob([s2ab(excelBinaryString)], {
  //       type: "application/octet-stream",
  //     });
  //     saveAs(excelBlob, getCurrentTimestampFilename("xlsx"));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  async function handleOrderExport() {
    try {
      if (shipmentType === "MARKETPLACE") {
        const marketplaceContent = await post(
          "/api/get-marketplace-orders-export",
          { selectedDate: dateFilter }
        );
        const ws = XLSX.utils.json_to_sheet(marketplaceContent);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "MarketplaceOrders");
        const excelBinaryString = XLSX.write(wb, {
          bookType: "xlsx",
          type: "binary",
        });
        const excelBlob = new Blob([s2ab(excelBinaryString)], {
          type: "application/octet-stream",
        });
        saveAs(excelBlob, getCurrentTimestampFilename("xlsx"));
      } else {
        const content = await post("/api/get-shipment-orders-export", {
          selectedDate: dateFilter,
        });
        const ws = XLSX.utils.json_to_sheet(content);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "ShipmentOrders");
        const excelBinaryString = XLSX.write(wb, {
          bookType: "xlsx",
          type: "binary",
        });
        const excelBlob = new Blob([s2ab(excelBinaryString)], {
          ype: "application/octet-stream",
        });
        saveAs(excelBlob, getCurrentTimestampFilename("xlsx"));
      }
    } catch (e) {
      console.log(e);
    }
  }

  function getCurrentTimestampFilename(extension) {
    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, "0");
    const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    const yy = String(currentDate.getFullYear()).slice(-2);
    const hh = String(currentDate.getHours()).padStart(2, "0");
    const min = String(currentDate.getMinutes()).padStart(2, "0");
    const ss = String(currentDate.getSeconds()).padStart(2, "0");
    const timestamp = `${dd}${mm}${yy}${hh}${min}${ss}`;
    return `Orders${timestamp}.${extension}`;
  }
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const { SearchBar } = Search;
  // let filteredOrders;
  // if (shipmentType !== "MARKETPLACE") {
  //   filteredOrders = orders.filter(
  //     (order) => order.order_type === shipmentType
  //   );
  // } else {
  //   filteredOrders = orders.filter(
  //     (order) =>
  //       shipmentType === "MARKETPLACE" && !order.hasOwnProperty("order_type")
  //   );
  // }
  // orders = filteredOrders;
  // let filteredOrders = orders.filter(
  //   (order) =>
  //     order.order_type === shipmentType ||
  //     (shipmentType === "MARKETPLACE" && !order.hasOwnProperty("order_type"))
  // );

  // const filteredOrders = useMemo(() => {
  //   if (shipmentType === "MARKETPLACE") {
  //     console.log("filter call");

  //     return orders.filter((order) => !order.hasOwnProperty("order_type"));
  //   }
  //   return orders.filter((order) => order.order_type === shipmentType);
  // }, [orders, shipmentType,activeTab]);

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    let filteredData;
    if (shipmentType === "MARKETPLACE") {
      console.log("Filter call for MARKETPLACE");
      filteredData = orders?.filter((order) => !order.hasOwnProperty("order_type"));
    } else {
      console.log("order type",orders);
      
      filteredData = orders?.filter((order) => order.order_type === shipmentType);
    }
    console.log("filtered data",filteredData);
    
    setFilteredOrders(filteredData);
  }, [orders, activeTab, shipmentType]);
  console.log("filtered data1", filteredOrders);
  

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
    selected: selectedRows.map((row) => row.id),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, row]);
      } else {
        setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedRows(rows);
      } else {
        setSelectedRows([]);
      }
    },
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  async function getEmailTemplates() {
    try {
      const content = await get("/api/email-template");

      setEmailTemplateList(content?.emailTemplates || []);
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }
  async function sendEmailToCustomer() {
    try {
      console.log("send email to cutomers", emailTemplate);

      setLoading(true);
      await post("/api/send-email-to-customer", {
        ...emailTemplate,
        email: sendEmail,
      });
      toastr.success("Success!");
      toggleModal();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Shipment Orders"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Shipment Orders", link: "/shipment-orders" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  shipmentType === "SHIPMENT" ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleShipmentTypeChange("SHIPMENT")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                PRIVATE SHIPMENT
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  shipmentType === "ECOM" ? "active" : ""
                                }`}
                                onClick={() => handleShipmentTypeChange("ECOM")}
                                style={{ cursor: "pointer" }}
                              >
                                E-COMMERCE SHIPMENT
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  shipmentType === "MARKETPLACE" ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleShipmentTypeChange("MARKETPLACE")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                MARKETPLACE
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PLACED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("PLACED")}
                              >
                                PLACED
                              </NavLink>
                            </NavItem>
                            {shipmentType === "MARKETPLACE" && (
                              <NavItem>
                                <NavLink
                                  className={`${
                                    activeTab === "READY_TO_DISPATCH"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTabChange("READY_TO_DISPATCH")
                                  }
                                >
                                  READY TO DISPATCH
                                </NavLink>
                              </NavItem>
                            )}
                            {shipmentType === "MARKETPLACE" && (
                              <NavItem>
                                <NavLink
                                  className={`${
                                    activeTab === "PROCESSING" ? "active" : ""
                                  }`}
                                  onClick={() => handleTabChange("PROCESSING")}
                                >
                                  PROCESSING
                                </NavLink>
                              </NavItem>
                            )}
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "RECEIVED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("RECEIVED")}
                              >
                                RECEIVED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "SHIPPED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("SHIPPED")}
                              >
                                SHIPPED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "REACHED_TO_LOCAL_PARTNER"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTabChange("REACHED_TO_LOCAL_PARTNER")
                                }
                              >
                                REACHED TO LOCAL PARTNER
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "DELIVERED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("DELIVERED")}
                              >
                                DELIVERED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "HOLD" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("HOLD")}
                              >
                                HOLD
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "CANCELLED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("CANCELLED")}
                              >
                                CANCELLED
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={filteredOrders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        exportCSV={true}
                        keyField="id"
                        data={filteredOrders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDocuments,
                          handleShowImageModal,
                          // resendPaymentLink,
                          differenceData,
                          handleShowDifferenceModal,
                          setSelectedOrderForEmail,
                          toggleModal,
                          setShowReasonModal,
                          getAllMarketplaceOrders,
                          getEmailFromAction,
                          getHandleDifferenceData,
                          getOrderDetails,
                          shipmentType,
                          typePass,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      value={searchString}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="3">
                                <div style={{ position: "relative" }}>
                                  <Flatpickr
                                    className="form-control d-block "
                                    placeholder="Filter by date"
                                    value={selectedDate}
                                    onChange={(e, v) => {
                                      setSelectedDate(v);
                                      if (e.length === 2) {
                                        const splittedDate = v.split(" to ");
                                        if (splittedDate.length === 1) {
                                          splittedDate.push(splittedDate[0]);
                                        }

                                        setDateFilter(splittedDate);
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "Y-m-d",
                                    }}
                                  />
                                  {selectedDate && (
                                    <Button
                                      className="clear-button"
                                      color="link"
                                      onClick={() => {
                                        setSelectedDate(null);
                                        setDateFilter([]);
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  )}
                                </div>
                              </Col>
                              <Col sm="2">
                                {selectedRows?.length ? (
                                  <MultipleOrderUpdate
                                    selectedOrders={selectedRows.map(
                                      (item) => item.id
                                    )}
                                    fetchDataAgain={getAllVendorRequests}
                                  />
                                ) : null}
                              </Col>
                                <Col sm={4}>
                                  <div
                                    className="text-sm-end"
                                    style={{ position: "relative" }}
                                  >
                              {shipmentType === "SHIPMENT" && (

                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={handleAddVendorModal}
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                      New Order
                                    </Button>
                              )}
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={handleOrderExport}
                                    >
                                      <i className="mdi mdi-download me-1" />{" "}
                                      Export Orders
                                    </Button>
                                  </div>
                                </Col>
                              
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                key={`table-${selectedRows?.length}`}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>{formPageTitle}</ModalHeader>
        <ModalBody>
          <PlaceShipmentOrderForUser
            getAllVendorRequests={getAllVendorRequests}
            handleAddVendorModal={handleAddVendorModal}
          />
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>Order Info</ModalHeader>
        <ModalBody>
          {handleOrderDetails?.order_type ? (
            <>
              <div>
                {handleOrderDetails?.order_type ? <h5>Product Images</h5> : ""}
                {handleOrderDetails?.product_image_urls?.map((el) => {
                  return (
                    <>
                      <img
                        src={`${
                          process.env.REACT_APP_AUTHDOMAIN
                        }/api/uploads/${el?.replace(/\\/g, "/")}`}
                        alt="productimage"
                        width="200px"
                      />
                      <Button
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          handleDownload(
                            `${
                              process.env.REACT_APP_AUTHDOMAIN
                            }/api/uploads/${handleOrderDetails?.product_image_urls[0]?.replace(
                              /\\/g,
                              "/"
                            )}`
                          )
                        }
                      >
                        Download
                      </Button>
                    </>
                  );
                })}
              </div>
              <div>
                {handleOrderDetails?.package_image_urls?.length ? (
                  <h5 style={{ marginTop: "20px" }}>Package Images</h5>
                ) : (
                  ""
                )}
                {handleOrderDetails?.package_image_urls?.map((el) => {
                  return (
                    <>
                      <img
                        src={`${
                          process.env.REACT_APP_AUTHDOMAIN
                        }/api/uploads/${el?.replace(/\\/g, "/")}`}
                        alt=""
                        width="200px"
                      />
                      <Button
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          handleDownload(
                            `${
                              process.env.REACT_APP_AUTHDOMAIN
                            }/api/uploads/${el?.replace(/\\/g, "/")}`
                          )
                        }
                      >
                        Download
                      </Button>
                    </>
                  );
                })}
              </div>
              <div>
                {handleOrderDetails?.ecom_shipment_urls?.length ? (
                  <h5 style={{ marginTop: "20px" }}>Ordered E-Commerce URLs</h5>
                ) : (
                  ""
                )}
                {handleOrderDetails?.ecom_shipment_urls?.map((el) => {
                  return (
                    <div style={{ cursor: "pointer" }}>
                      <a href={el} target="_blank" rel="noreferrer">
                        {el}
                      </a>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div>
              {handleOrderDetails?.product ? <h5>Product Images</h5> : ""}
              <img
                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${handleOrderDetails?.product?.image_urls[0]}`}
                alt="productimage"
                width="200px"
              />
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() =>
                  handleDownload(
                    `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${handleOrderDetails?.product?.image_urls[0]}`
                  )
                }
              >
                Download
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal
        size="ml"
        isOpen={showDifferenceModal}
        toggle={handleShowDifferenceModal}
      >
        <ModalHeader toggle={handleShowDifferenceModal}>
          Manage Difference
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="difference">
                  Additional Amount
                </Label>
                <Input
                  id="difference"
                  name="difference"
                  type="text"
                  value={differenceAmount}
                  onChange={(e) =>
                    setDifferenceAmount(
                      e.target.value
                        ? e.target.value?.replace(/[^0-9.]/g, "")
                        : ""
                    )
                  }
                  className="form-control"
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="remarks">
                  Remarks (for hold)
                </Label>
                <textarea
                  id="remarks"
                  name="remarks"
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ marginTop: "30px" }}
                color="primary"
                className="primary"
                disabled={loading}
                onClick={createDifferencePaymentLink}
              >
                {loading ? `Loading...` : `Hold and Send payment link`}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        size="xl"
        isOpen={showReasonModal}
        toggle={() => setShowReasonModal(false)}
      >
        <ModalHeader toggle={() => setShowReasonModal(false)}>
          Add Hold Or Cancellation Reason
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="remarks">
                  Reason
                </Label>
                <textarea
                  id="remarks"
                  name="remarks"
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ marginTop: "30px" }}
                color="primary"
                className="primary"
                disabled={loading}
                onClick={addReason}
              >
                {loading ? `Loading...` : `Save`}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={displayModal}
        role="document"
        autoFocus={true}
        centered={true}
        className="composemodal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Send Email To Customer</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <h6 style={{ marginTop: "10px" }}>Select Template</h6>
            <SearchableDropdown
              defaultValue={emailTemplate}
              options={emailTemplateList.map((el) => ({
                ...el,
                label: el.title,
                value: el.id,
              }))}
              onSelect={setEmailTemplate}
            />
          </div>
          <Form>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Title"
                value={emailTemplate?.title}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </div>

            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Subject"
                value={emailTemplate?.subject}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="mb-3">
              <Editor
                containerProps={{ style: { height: "200px" } }}
                value={emailTemplate?.message}
                onChange={(e) => {
                  setEmailTemplate((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }));
                }}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button coloe="secondary" type="button" onClick={toggleModal}>
            Close
          </Button>
          <Button
            disabled={loading}
            onClick={sendEmailToCustomer}
            type="button"
            color="primary"
          >
            {loading ? `Loading..` : `Send`}{" "}
            <i className="fab fa-telegram-plane ms-1"></i>
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

function PlaceShipmentOrderForUser({
  handleAddVendorModal,
  getAllVendorRequests,
}) {
  const [currentStage, setCurrentStage] = React.useState(1);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedPackageSize, setSelectedPackageSize] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [selectedPickupAddress, setSelectedPickupAddress] =
    React.useState(null);
  const [shipmentType, setShipmentType] = React.useState(null);
  const [orderType, setOrderType] = React.useState(null);
  const [shipmentCharges, setShipmentCharges] = React.useState([]);
  const [insuranceCharges, setInsuranceCharges] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [customPackageSize, setCustomPackageSize] = React.useState(null);
  const [additionalProperties, setAdditionalProperties] = React.useState(null);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);

  async function createShipmentOrderForUser() {
    let customPackageId = null;
    if (selectedPackageSize?.value === "Custom") {
      const customPackage = await post("/api/create-package-size", {
        ...customPackageSize,
        is_custom: true,
      });
      customPackageId = customPackage.id;
    }
    try {
      const orderObj = {
        is_created_by_admin: true,
        shipment_type: shipmentType.shipping_type,
        order_type: "SHIPMENT",
        user_id: selectedUser.value,
        package_size_id: customPackageId
          ? customPackageId
          : selectedPackageSize.id,
        delivery_address_id: selectedAddress.value,
        pickup_address_id: selectedPickupAddress.value,
        category_id: selectedCategory.value,
        sub_category_id: selectedSubCategory.value,
        ...(additionalProperties?.additional_discount && {
          additional_discount_applied:
            additionalProperties?.additional_discount,
        }),
        ...(additionalProperties?.insurance_value && {
          insurance_applied: additionalProperties?.insurance_value,
        }),
      };
      // return
      const orderInfo = await post("/api/create-shipment", orderObj);
      await post("/api/create-custom-payment-link", {
        shipment_order_id: orderInfo.id,
      });
      handleAddVendorModal();
      getAllVendorRequests();
      toastr.success("Order Created Successfully");
    } catch (e) {
      console.log(e);
      toastr.error("Failed creating an order");
    }
  }

  return (
    <>
      {currentStage === 1 ? (
        <Stage1SelectUserAndPackageSize
          category={category}
          setCategory={setCategory}
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedPackageSize={setSelectedPackageSize}
          setSelectedUser={setSelectedUser}
          setCurrentStage={setCurrentStage}
          setShipmentCharges={setShipmentCharges}
          selectedUser={selectedUser}
          selectedPackageSize={selectedPackageSize}
          setCustomPackageSize={setCustomPackageSize}
          customPackageSize={customPackageSize}
          setInsuranceCharges={setInsuranceCharges}
          insuranceCharges={insuranceCharges}
        />
      ) : null}
      {currentStage === 2 ? (
        <Stage2SelectUserAddress
          setSelectedAddress={setSelectedAddress}
          selectedPickupAddress={selectedPickupAddress}
          setSelectedPickupAddress={setSelectedPickupAddress}
          selectedUser={selectedUser}
          selectedAddress={selectedAddress}
          setCurrentStage={setCurrentStage}
          setShipmentType={setShipmentType}
          shipmentType={shipmentType}
          orderType={orderType}
          currentStage={currentStage}
          setOrderType={setOrderType}
          shipmentCharges={shipmentCharges}
          insuranceCharges={insuranceCharges}
          customPackageSize={customPackageSize}
          selectedPackageSize={selectedPackageSize}
          createShipmentOrderForUser={createShipmentOrderForUser}
          getAllVendorRequests={getAllVendorRequests}
          setTotalAmount={setTotalAmount}
          totalAmount={totalAmount}
          setAdditionalProperties={setAdditionalProperties}
          additionalProperties={additionalProperties}
        />
      ) : null}
    </>
  );
}

export default ShipmentOrders;
