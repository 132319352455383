import React, { useState } from "react";
import { Input, Label, FormGroup, Col, Row, Spinner, Button } from "reactstrap";
import ProductImages from "./ProductImages";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import SearchableDropdown from "./SearchableDropdown";
import SearchableDropdownProducts from "./SearchableDropdownProducts";
import { get, post } from "../helpers/api_helper";

function AddVariantProductModal({
  handleShowCreateVariantModal,
  user,
  calculateShippingCost,
  parentProduct,
  setParentProduct,
  loading,
}) {
  const navigation = useHistory();
  const [product, setProduct] = useState(
    parentProduct?.variantToBeEdited ? parentProduct?.variantToBeEdited : {}
  );
  const [selectedFiles, setSelectedFiles] = useState(
    parentProduct?.variantToBeEdited?.image_urls
      ? parentProduct?.variantToBeEdited?.image_urls.map((item) => ({
          name: item,
          preview: `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item}`,
          oldImage: true,
        }))
      : []
  );
  console.log("products",product);
  
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setProduct((prev) => ({ ...prev, [name]: value.replace(/[^0-9.]/g, "") }));
  // };
    const handleChange = (event) => {
      const { name, value } = event.target;
      setProduct((prev) => ({
        ...prev,
        [name]: name === "color" ? value : value.replace(/[^0-9.]/g, ""),
      }));
    };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const productPrice = parseFloat(product.discountedPrice)
      ? product.discountedPrice
      : product.MRP;

    if (productPrice < calculateShippingCost(product.weight, "AIR") ||productPrice < calculateShippingCost(product.weight, "SHIP")) {
      alert("Product current price must be greater than shipping cost");
      return;
    }
    if (parentProduct?.is_shipping_free) {
      if (
        window.confirm(
          `Free shipping is enabled for this product \nAmount of shipping for Air: ${calculateShippingCost(
            product.weight,
            "AIR"
          )}, Ship: ${calculateShippingCost(
            product.weight,
            "SHIP"
          )} as per selection by user will be deducted from vendor wallet amount`
        ) === false
      ) {
        return;
      }
    }
    try {
      if (
        !selectedFiles.length ||
        !product.MRP ||
        !product.width ||
        !product.length ||
        !product.weight ||
        !product.height ||
        !product.color ||
        selectedFiles.length > 3
      ) {
        let errorMessage = "Please fill in the following fields:\n";

        if (product.width === "") {
          errorMessage += "- Width\n";
        }
        if(product.color === ""){
          errorMessage += "- Color \n";
        }
        if (product.length === "") {
          errorMessage += "- Length\n";
        }
        if (product.weight === "") {
          errorMessage += "- Weight\n";
        }
        if (product.height === "") {
          errorMessage += "- Height\n";
        }
        if (!selectedFiles.length) {
          errorMessage += "- At least one file must be selected\n";
        }
        if (selectedFiles.length > 3) {
          errorMessage += "- Only 3 files can be uploaded\n";
        }

        if (!product.MRP) {
          errorMessage += "- MRP\n";
        }
        if (
          product?.selectedAttributes?.length !==
          parentProduct.attributes?.length
        ) {
          errorMessage += "- Select variant type\n";
        }

        alert(errorMessage);
      } else {
        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
          if (!selectedFiles[i]?.oldImage) {
            formData.append("files", selectedFiles[i]);
          }
        }

        formData.append(
          "objectDetails",
          JSON.stringify({
            ...product,
            product_id: parentProduct.id,
            ...(product?.id && {
              id: product?.id,
            }),
            old_images: selectedFiles.filter((el) => el.oldImage),
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        const responseObj = await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/product/create-single-variant`,
          config
        );
        const res = await responseObj.json();
        if (res?.message) {
          return false;
        }
        toastr.success("Variant Created!", "Success");
        const variants = await get(
          `/api/product/variants/${parentProduct?.id}`
        );
        setParentProduct((prev) => ({
          ...prev,
          variants: variants,
        }));
        handleShowCreateVariantModal();
        setProduct({});
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.error(e);
    }
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles((prev) => [...prev, ...files]);
  };
  function handleSelectedVariantsChanged(item) {
    if (product?.selectedAttributes) {
      const foundItem = product.selectedAttributes.findIndex(
        (element) => element.parent === item.parent
      );
      if (foundItem !== -1) {
        product.selectedAttributes.splice(foundItem, 1);

        product.selectedAttributes.push(item);
      } else {
        product.selectedAttributes.push(item);
      }
    } else {
      product.selectedAttributes = [];
      product.selectedAttributes.push(item);
    }
    setProduct(product);
  }
  return (
    <>
      <form>
        {parentProduct?.attributes.map((item) => {
          return (
            <>
              <h6>{item.name}</h6>
              <div>
                <SearchableDropdownProducts
                  defaultValue={
                    product?.selectedAttributes
                      ? product?.selectedAttributes?.filter(
                          (element) => element.parent === item.name
                        )
                      : ""
                  }
                  options={item?.values?.map((element) => ({
                    label: element,
                    value: element,
                    parent: item.name,
                    parent_id: item.id,
                    ...(element?.id && { id: element.id }),
                  }))}
                  onSelect={handleSelectedVariantsChanged}
                />
              </div>
            </>
          );
        })}
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="price">
                MRP
              </Label>
              <Input
                id="mrp"
                pattern="[0-9]+([\.,][0-9]+)?"
                name="MRP"
                value={product?.MRP}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="price">
                Discounted Price
              </Label>
              <Input
                id="price"
                name="discountedPrice"
                value={product?.discountedPrice}
                onChange={handleChange}
                type="text"
                pattern="[0-9]+([\.,][0-9]+)?"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="weight">
                Weight (KG)
              </Label>
              <Input
                id="weight"
                pattern="[0-9]+([\.,][0-9]+)?"
                name="weight"
                value={product?.weight}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="height">
                Height (CM)
              </Label>
              <Input
                id="height"
                pattern="[0-9]+([\.,][0-9]+)?"
                name="height"
                value={product?.height}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="length">
                Length (CM)
              </Label>
              <Input
                id="length"
                pattern="[0-9]+([\.,][0-9]+)?"
                name="length"
                value={product?.length}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="width">
                Width (CM)
              </Label>
              <Input
                id="width"
                pattern="[0-9]+([\.,][0-9]+)?"
                name="width"
                value={product?.width}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <Label className="form-label" htmlFor="color">
                Color
              </Label>
              <Input
                id="color"
                // pattern="[0-9]+([\.,][0-9]+)?"
                name="color"
                value={product?.color}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="airshippingcharges">
              Air Shipping Charges
            </Label>
            <Input
              id="airshippingcharges"
              name="airshippingcharges"
              value={calculateShippingCost(product.weight, "AIR")}
              disabled
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="shipshippingcharges">
              Ship Shipping Charges
            </Label>
            <Input
              id="shipshippingcharges"
              name="shipshippingcharges"
              value={calculateShippingCost(product.weight, "SHIP")}
              disabled
              type="text"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <ProductImages
        handleAcceptedFiles={handleAcceptedFiles}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px auto",
        }}
      >
        <Button
          size="md"
          disabled={loading}
          onClick={handleSubmit}
          style={{
            backgroundColor: "#0077B6",
            color: "white",
          }}
        >
          {loading ? (
            <Spinner
              style={{ width: "2rem", height: "2rem" }}
              children={false}
            />
          ) : (
            "Submit here"
          )}
        </Button>
      </div>
    </>
  );
}

export default AddVariantProductModal;
function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
}
