import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import SearchableDropdown from "../../components/SearchableDropdown";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import Editor from "react-simple-wysiwyg";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Table,
} from "reactstrap";
import toastr from "toastr";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import { useSelector } from "react-redux";
const Umbrella = () => {
  const navigation = useHistory();
  const apistate = useSelector((state) => state.Layout.umbrellaApiCallState);
 const [emailTemplate, setEmailTemplate] = useState({
   title: "",
   subject: "",
   message: "",
 });
 const [emailTemplateList, setEmailTemplateList] = useState([]);
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [listviewData, setListviewData] = useState([]);
  const [subjectContent, setSubjectContent] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [selectedUmbrella, setSelectedUmbrella] = useState(false);
  const [shipmentType, setShipmentType] = useState("SHIPPED");
  const [containerId, setContainerId] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);

  useEffect(() => {
    getAllCategories();
    getEmailTemplates();
  }, [shipmentType]);
  useEffect(() => {
    getAllCategories();
  }, [apistate]);

  useEffect(async () => {
    if (containerId !== "") {
      const listviewData = await post("/api/get-container-orders", {
        containerId,
      });
      setListviewData(listviewData?.data);

      setIsTableVisible(true);
      if (listviewData.data) {
      }
    } else {
      setContainerId("");
    }
  }, [containerId]);

  function toggleModal() {
    setDisplayModal((prev) => !prev);
    setEmailContent("");
    setSubjectContent("");
  }
  async function getEmailTemplates() {
    try {
      const content = await get("/api/email-template");
      console.log("content",content);
      
      setEmailTemplateList(content?.emailTemplates || []);
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }

  async function getAllCategories() {
    try {
      const content = await get("/api/umbrella", {});
      const filteredData =
        shipmentType === "SHIPPED"
          ? content.filter((item) => item.order_status === "SHIPPED")
          : content.filter(
              (item) => item.order_status === "REACHED_TO_LOCAL_PARTNER"
            );
      setOrders(filteredData);
    } catch (e) {
      console.log(e);
    }
  }

  async function sendEmail() {
    try {
      setLoading(true);
      if (!emailContent) {
        toastr.error("Please enter email content");
        return;
      }

      await post("/api/umbrella/send-email", {
        containerId: selectedUmbrella,
        content: { emailContent, subjectContent },
      });
      toggleModal();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];
  function handleTableCloseClick() {
    setIsTableVisible(false);
  }
  const handleSelect = (selectedOption) =>{
    console.log("selected option", selectedOption);
    setSubjectContent(selectedOption.subject);
    setEmailContent(selectedOption.message);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Umbrella Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Umbrella", link: "/umbrella" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  shipmentType === "SHIPPED" ? "active" : ""
                                }`}
                                onClick={() => setShipmentType("SHIPPED")}
                                style={{ cursor: "pointer" }}
                              >
                                SHIPPED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  shipmentType === "REACHED TO LOCAL PARTNER"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setShipmentType("REACHED TO LOCAL PARTNER")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                REACHED TO LOCAL PARTNER
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col sm="12">
                      <div className="text-sm-end">
                        <a
                          type="button"
                          className="btn-rounded mb-2 me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            navigation.push("/umbrella-new");
                          }}
                        >
                          <i className="mdi mdi-plus me-1" /> Add New Umbrella
                        </a>
                      </div>
                    </Col>
                  </Row>

                  {orders.length === 0 ? (
                    <p></p>
                  ) : (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      data={orders}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={orders}
                          columns={EcommerceOrderColumns(
                            toggleModal,
                            setSelectedUmbrella,
                            shipmentType,
                            setContainerId
                          )}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div
                                className="table-responsive"
                                style={{ minHeight: "200px" }}
                              >
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                              </div>
                              {console.log("listview data", listviewData)}
                              {isTableVisible === true && (
                                <>
                                  <div style={{ position: "relative" }}>
                                    <span
                                      onClick={handleTableCloseClick}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                        fontSize: "1.5em",
                                        color: "grey",
                                        zIndex: 1,
                                      }}
                                    >
                                      &times;
                                    </span>
                                    {listviewData.length >= 0 && (
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>DATE</th>
                                            <th>ORDER ID</th>
                                            <th>FULL NAME</th>
                                            <th>MOBILE NUMBER</th>
                                            <th>EMAIL</th>
                                            <th>SHIPMENT TYPE</th>
                                            <th>INSURANCE</th>
                                            <th>INSURANCE VALUE</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {listviewData.map((items, index) => (
                                            <tr key={index}>
                                              <th scope="row">
                                                {items?.createdAt?.split("T")[0]
                                                  ? items?.createdAt?.split(
                                                      "T"
                                                    )[0]
                                                  : "-"}
                                              </th>
                                              <td>
                                                {items?.display_id
                                                  ? items?.display_id
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.user?.first_name +
                                                " " +
                                                items?.user?.last_name
                                                  ? items?.user?.first_name +
                                                    " " +
                                                    items?.user?.last_name
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.user?.mobilenumber
                                                  ? items?.user?.mobilenumber
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.user?.email
                                                  ? items?.user?.email
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.shipment_type
                                                  ? items?.shipment_type
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.insuranceCharges
                                                  ? `$${items?.insuranceCharges}`
                                                  : "-"}
                                              </td>
                                              <td>
                                                {items?.insurance_applied
                                                  ? `$${items?.insurance_applied}`
                                                  : "-"}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    )}
                                  </div>
                                </>
                              )}

                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={displayModal}
        role="document"
        autoFocus={true}
        centered={true}
        className="composemodal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Send Email To Customers</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <h6 style={{ marginTop: "10px" }}>Select Template</h6>
            <SearchableDropdown
              defaultValue={emailTemplate}
              placeholder="Select email template"
              options={emailTemplateList.map((el) => ({
                ...el,
                label: el.title,
                value: el.id,
              }))}
              // onSelect={setEmailTemplate}
              onSelect={(selectedOption) => handleSelect(selectedOption)}
            />
          </div>
          <Form>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Subject"
                value={subjectContent}
                onChange={(e) => setSubjectContent(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Editor
                containerProps={{ style: { height: "200px" } }}
                value={emailContent}
                onChange={(event) => {
                  const content = event.target.value;
                  setEmailContent(content);
                }}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={sendEmail}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default Umbrella;
