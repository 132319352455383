import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import toastr from "toastr";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Editor from "react-simple-wysiwyg";
import { get, post } from "../../helpers/api_helper";

const EmailTemplates = () => {
  const user = useSelector((state) => state.userAuthInfo.user);
  const [emailTemplate, setEmailTemplate] = useState({
    title: "",
    subject: "",
    message: "",
    image: null,
  });
  
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  function toggleModal() {
    setDisplayModal((prev) => !prev);
  }
  async function getEmailTemplates() {
    try {
      const content = await get("/api/email-template");
      setEmailTemplateList(content?.emailTemplates || []);
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
    }
  }
  
  async function createEmailTemplates() {
    try {
      const formData = new FormData();
      formData.append("title", emailTemplate?.title);
      formData.append("subject", emailTemplate?.subject);
      formData.append("message", emailTemplate?.message);
      if(emailTemplate?.id){
        formData.append("id",emailTemplate?.id)
      }
      if(emailTemplate.image){
        formData.append("mailImage", emailTemplate?.image);
      }else{
        return toastr.error("please select mail image")
      }

      const config = {
        method: "POST",
        body: formData,
      };

      const responseObj = await fetch(`${process.env.REACT_APP_AUTHDOMAIN}/api/email-template-create`,config);
      const data = await responseObj.json();
      console.log("responce object", data?.message);
      if(responseObj.status === 400){
        return toastr.error(data?.message);
      }
  
      // await post("/api/email-template-create", { ...emailTemplate });
      toastr.success("Success!");
      toggleModal();
      getEmailTemplates();
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }

  useEffect(() => {
    getEmailTemplates();
  }, []);
  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 10,
    totalSize: emailTemplate?.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

   const variables = [
     { label: "First Name", value: "{{first_name}}" },
     { label: "Last Name", value: "{{last_name}}" },
     { label: "Email", value: "{{email}}" },
     { label: "Mobile Number", value: "{{mobile_number}}" },
   ];

   // Handle variable insertion
   const handleVariableSelect = (event) => {
     const selectedVariable = event.target.value;
     if (selectedVariable) {
       setEmailTemplate((prev) => ({
         ...prev,
         message: prev.message + " " + selectedVariable,
       }));
     }
   };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Email Templates"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Email Templates", link: "/email-templates" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={emailTemplateList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        exportCSV={true}
                        keyField="id"
                        data={emailTemplateList}
                        columns={EcommerceOrderColumns(
                          setEmailTemplate,
                          toggleModal,
                          getEmailTemplates
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={() => {
                                      setEmailTemplate({
                                        title: "",
                                        subject: "",
                                        message: "",
                                      });
                                      toggleModal();
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Create
                                    Email Template
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Modal
        isOpen={displayModal}
        role="document"
        autoFocus={true}
        centered={true}
        className="composemodal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Email Template</ModalHeader>
          <ModalBody>
            <Form>
              <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={emailTemplate?.title}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={emailTemplate?.subject}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-3">
                <Editor
                  containerProps={{ style: { height: "200px" } }}
                  value={emailTemplate?.message}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <Input
                  type="file"
                  className="form-control"
                  placeholder="Image"
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      image: e.target.files[0],
                    }));
                  }}
                />
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button coloe="secondary" type="button" onClick={toggleModal}>
              Close
            </Button>
            <Button
              onClick={createEmailTemplates}
              type="button"
              color="primary"
            >
              Save <i className="fab fa-telegram-plane ms-1"></i>
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}

      <Modal
        isOpen={displayModal}
        role="document"
        autoFocus={true}
        centered={true}
        className="composemodal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Email Template</ModalHeader>
          <ModalBody>
            <Form>
              <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={emailTemplate?.title}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={emailTemplate?.subject}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <Editor
                  containerProps={{ style: { height: "200px" } }}
                  value={emailTemplate?.message}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="variable-select">Insert Variable:</label>
                <Input
                  type="select"
                  id="variable-select"
                  onChange={handleVariableSelect}
                >
                  <option value="">Select a variable</option>
                  {variables.map((variable, index) => (
                    <option key={index} value={variable.value}>
                      {variable.label}
                    </option>
                  ))}
                </Input>
              </div>

              <div className="mb-3">
                <Input
                  type="file"
                  className="form-control"
                  placeholder="Image"
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      image: e.target.files[0],
                    }));
                  }}
                />
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Close
            </Button>
            <Button
              onClick={createEmailTemplates}
              type="button"
              color="primary"
            >
              Save <i className="fab fa-telegram-plane ms-1"></i>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EmailTemplates;
